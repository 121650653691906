.mySwiper-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  img {
    height: 50vh;
    object-fit: cover;
  }
}

.swiper {
  width: 100%;
  padding-top: 50px;
  padding-bottom: 50px;
}

.swiper-slide {
  background-position: center;
  background-size: cover;
  width: 55%;

  img {
    display: block;
    width: 100%;
  }
  .custom-icon-link {
    img {
      min-height: 20px !important;
      min-width: 20px !important;
      height: 20px !important;
      width: 20px !important;
    }
  }
}
